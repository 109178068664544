import { Spacer, Text } from '@vapor/ui';
import { styled } from 'buttered';
import React from 'react';
import { Box, BoxInner } from '../components/box';
import { Logo } from '../components/logo';

let Wrapper = styled('div')`
  text-align: center;
`;

let Center = styled('div')`
  width: fit-content;
  margin: 0px auto;
`;

let LogoWrapper = styled(Center)`
  margin: 0px auto 40px auto;
`;

export default function NotFound() {
  return (
    <Box>
      <BoxInner>
        <Wrapper>
          <LogoWrapper>
            <Logo size="70px" />
          </LogoWrapper>

          <Text as="h1" size={28} weight={600}>
            404 - Not Found.
          </Text>

          <Spacer height="15px" />

          <Text as="p" size={14} weight={500}>
            That's an error, but you probably know that.
          </Text>
        </Wrapper>
      </BoxInner>
    </Box>
  );
}
